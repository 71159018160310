import React, { useState } from "react"
import firebase from "gatsby-plugin-firebase"

// The type definition for the firebase context data.

// The firebase context that will store the firebase instance and other useful variables.
export const FirebaseContext = React.createContext({
    authToken: null,
    firebase,
    setAuthToken: () => {},
    user: null,
    initialized: false,
})

const db = firebase.firestore()

export const collaboratorAccessRef = db.collection("approved-collaborators")
export const collaboratorsRef = db.collection("collaborators")
export const readwatchRef = db.collection("read-watch")
export const wePhealtThatRef = db.collection("we-phealt-that")
export const originalContentRef = db.collection("original-content")

// The provider that will store the logic for manipulating the firebase instance and variables.
export const FirebaseProvider = ({ children }) => {
    // If we have a window and the authToken already exists in localstorage then initialize the authToken value otherwise null.
    const [authToken, setAuthToken] = React.useState(
        typeof window !== "undefined"
            ? window.localStorage.getItem("authToken")
            : null
    )
    const [user, setUser] = useState(null)
    const [initialized, setInitialized] = useState(false)

    // A method for setting the authToken in state and local storage.
    const onSetAuthToken = token => {
        setAuthToken(token)
        localStorage.setItem("authToken", token)
    }

    // If we have the window object and there is no authToken then try to get the authToken from local storage.
    React.useEffect(() => {
        if (typeof window !== "undefined" && !authToken) {
            const token = window.localStorage.getItem("authToken")
            if (token) {
                onSetAuthToken(token)
            }
        }
    }, [authToken])

    React.useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                // User is signed in.
                setAuthToken(user.refreshToken)
                setUser(user)
                setInitialized(true)
            } else {
                // No user is signed in.
                // console.log("no user signed in")
                setInitialized(true)
            }
        })
    }, [])

    return (
        <FirebaseContext.Provider
            value={{
                authToken,
                firebase,
                setAuthToken: onSetAuthToken,
                user,
                initialized: initialized,
            }}
        >
            {children}
        </FirebaseContext.Provider>
    )
}
